<template>
    <div v-if="!access">
        <NotFound />
    </div>
    <div v-else>
        <div class="pb-2 mb-3 border-bottom">
            <h2>Employee Shifts</h2>
        </div>
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <router-link to="/">Dashboard</router-link>
                </li>
                <li class="breadcrumb-item active" aria-current="page">Employee Shifts</li>
            </ol>
        </nav>
        <b-container fluid>
            <div v-if="api_error">
                <b-alert
                    :show="alertDismissCountDown"
                    dismissible
                    variant="danger"
                    @dismissed="alertDismissCountDown=0"
                    @dismiss-count-down="alertCountDownChanged"
                >{{api_error}}</b-alert>
            </div>
            <b-row>
                <b-col lg="3" class="mb-1rem">
                    <router-link
                        v-if="hasAnyPermission(['CAN_ASSIGN_SHIFTS'])"
                        :to="{name: 'assign-employee-shift'}"
                        class="btn btn-primary text-right"
                        tag="button"
                    >
                        <font-awesome-icon icon="plus"></font-awesome-icon>&nbsp; Assign Employee Shift
                    </router-link>
                </b-col>
                <b-col lg="3">
                    <b-form-group
                        label="Shift"
                        label-cols-sm="3"
                        label-align-sm="right"
                        label-size="sm"
                        class="mb-1"
                        label-for="shiftfilter"
                        style="font-weight:bold;font-size:1rem !important;"
                    >
                        <b-input-group >
                        <b-form-select id="shiftfilter" v-model="urlBody.shift_id" :options="shifts"></b-form-select>
                        </b-input-group>
                    </b-form-group>
                </b-col>
                <b-col lg="1">
                        <button
                            type="button"
                            class="btn btn-primary text-left "
                            v-on:click="onSelection()"
                        >Filter</button>
                </b-col>
                <b-col lg="5" class="mb-1rem">
                    <b-form-group
                        label="Search"
                        label-cols-sm="3"
                        label-align-sm="right"
                        label-size="sm"
                        label-for="filterInput"
                        class="mb-0"
                        style="font-size:1rem !important;"
                    >
                        <b-input-group size="md" >
                            <div class="col-md-10">
                                <EmployeeList  :reporting_to="employeeReportingto" @emp_id="getEmpId" ref="employeeId"></EmployeeList>
                            </div>
                            <b-button
                                :disabled="!filter"
                                @click="onFiltered"
                                class="btn btn-primary col-md-2"
                                variant="primary"
                            >Search</b-button>
                        </b-input-group>
                    </b-form-group>
                </b-col>
            </b-row>

            <b-table
                show-empty
                striped
                hover
                :items="items"
                :fields="fields"
                no-local-sorting
                @sort-changed="customSorting"
                @filtered="onFiltered"
                responsive
            >   
            <template v-slot:cell(status)="row">
                  <b-badge :variant="setBadgeColor(row.item.status)">{{row.item.status}}</b-badge>
            </template>
            <template v-slot:cell(actions)="row">
                <span v-if="row.item.status == 'ACTIVE'" class="actions">
                    <span v-b-tooltip.hover title="Edit" v-if="(hasAnyPermission(['CAN_ASSIGN_SHIFTS'])) && (row.item.to_date > new Date().toISOString().slice(0, 10))"  @click="$router.push({name:'edit-employee-shift', params:{id:row.item.id}})" class="text-primary circle ml-l">
                        <font-awesome-icon
                            icon="pen"
                            v-b-modal.modal-center
                        ></font-awesome-icon>
                    </span>
                    <span v-b-tooltip.hover title="Deactivate" v-if="hasAnyPermission(['CAN_ASSIGN_SHIFTS'])"  @click="info(row.item, row.index, $event.target)" class="text-danger circle ml-l">
                    <font-awesome-icon
                        icon="ban"
                        v-b-modal.modal-center
                    ></font-awesome-icon>
                    </span>
                </span>
                <span class="actions">
                    <span v-b-tooltip.hover class="circle" v-b-modal.modal-center @click="openHistory(row.item,$event.target)" title="History"> 
                        <font-awesome-icon
                            icon="history" 
                            style="cursor: pointer;"
                        />
                    </span>
                </span>
            </template>
            <template v-slot:cell(emp_id)="row">
                {{row.item.employee.full_name}} [IES-{{row.item.emp_id}}]
            </template>
            </b-table>

            <b-row align-h="between" v-if="totalRows">
                <b-col lg="4" class="p-3">
                    <div class="row input-group">
                        <div class="input-group-append my-2">Showing</div>
                        <div class="mx-1em">
                            <select
                                class="form-control"
                                v-model="urlBody.per_page"
                                @change="changePageCount"
                            >
                                <option
                                    v-for="option in pageOptions"
                                    :key="option.index"
                                >{{option}}</option>
                            </select>
                        </div>
                        <div class="input-group-append my-2" v-if="totalRows>urlBody.per_page">of {{totalRows}}</div>
                    </div>
                </b-col>
                <b-col lg="4" class="p-3">
                    <nav aria-label="Page navigation" class="justify-content-center">
                        <ul class="pagination float-right">
                            <li class="page-item">
                                <span
                                    class="page-link"
                                    v-on:click="pagination(urlBody.page - 1)"
                                    v-if="urlBody.page > 1"
                                    style="cursor:pointer"
                                >
                                    <font-awesome-icon class="mr-2" icon="angle-double-left"></font-awesome-icon>Previous
                                </span>
                                <span
                                    class="page-link text-muted"
                                    style="cursor:not-allowed !important;"
                                    v-else
                                >
                                    <font-awesome-icon class="mr-2" icon="angle-double-left"></font-awesome-icon>Previous
                                </span>
                            </li>
                            <li class="page-item">
                                <span
                                    class="page-link text-muted"
                                    style="cursor:not-allowed !important;"
                                    v-if="urlBody.page == lastPage"
                                >
                                    Next
                                    <font-awesome-icon class="mr-2" icon="angle-double-right"></font-awesome-icon>
                                </span>
                                <span
                                    class="page-link"
                                    v-else
                                    v-on:click="pagination(urlBody.page +1)"
                                    style="cursor:pointer"
                                >
                                    Next
                                    <font-awesome-icon class="mr-2" icon="angle-double-right"></font-awesome-icon>
                                </span>
                            </li>
                        </ul>
                    </nav>
                </b-col>
            </b-row>
            <b-modal
                :id="infoModal.id"
                :title="infoModal.title"
                centered
                size="md"
                @hide="resetInfoModal"
            >
                <p v-html="message" class="float-left"></p>
                <div slot="modal-footer" class="w-100">
                    <b-button class="mt-3 float-right" @click="cancel()">Cancel</b-button>
                    <b-button
                        class="mt-3 float-right"
                        variant="danger"
                        v-on:click="deleteEmployeeShift(infoModal.content)"
                        style="margin-right:10px;"
                    >Deactivate</b-button>
                </div>
            </b-modal>

            <!-- History modal -->
            <b-modal 
                :id="history.id" 
                centered  
                size="xl" 
                hide-footer
            >
                <template #modal-title>
                    <span class="h4 font-weight-bold">{{history.title}}</span> 
                </template>
                <b-table
                    show-empty
                    striped
                    hover
                    :items="history.rows"
                    :fields="history.fields"
                    no-local-sorting
                    responsive
                >
                </b-table>
            </b-modal>

        </b-container>
    </div>
</template>

<script>
import NotFound from "../../errors/NotFound";
import default_error from "../../../assets/js/global";
import EmployeeList from "@/components/EmployeeList.vue";
export default {
    components: {
        NotFound,
        EmployeeList
    },
    data() {
        return {
            urlBody: {
                query: "",
                sort_by: "",
                order: "",
                page: 1,
                per_page: 10,
                shift_id:""
            },
            success: false,
            updated: false,
            deleted: "",
            api_error: "",
            sortBy: "emp_id",
            sortDesc: false,
            fields: [
                { label:"Employee" ,key: "emp_id", sortable: true },
                { label:"Shift",key: "shift.name", sortable: false },
                { key: "from_date", sortable: false,
                    formatter: value => {
                        return this.dateformat(value);
                    }

                },
                { key: "to_date", sortable: false,
                    formatter: value => {
                        return this.dateformat(value);
                    }
                },
                { label:"Week Offs" ,key: "week_off", sortable: false,
                    formatter: value => {
                        return this.week_offs(value);
                    }
                },
                { key: "status" } ,
                { key: "actions", label: "Actions" } 
            ],
            shifts:[
                { value:"" ,text:"Select Shift" },
            ],
            items: null,
            totalRows: 1,
            lastPage: 1,
            pageOptions: [10, 25, 50, 100],
            filter: null,
            infoModal: {
                id: "modal-sm",
                title: "",
                content: ""
            },
            access: false,
            alertDismissCountDown: 0,
            alertDismissSecs: 10,
            employeeReportingto:1,
            message:"",
            badge_colors:[
                {
                    status: "ACTIVE",
                    color:'success'
                },
                {
                    status: "INACTIVE",
                    color:'danger'
                },
            ],
            history: {
                id: 'employee-shift-history-modal',
                title: 'History',
                fields: [
                    { key: "shift_name" },
                    { key: "from_date",
                        formatter: value => {
                            return this.dateformat(value);
                        }

                    },
                    { key: "to_date",
                        formatter: value => {
                            return this.dateformat(value);
                        }
                    },
                    { label:"Week Offs" ,key: "week_off",
                        formatter: value => {
                            return this.week_offs(value);
                        }
                    },
                    { key: "created_by_name", label:"Created By", class: "text-nowrap"},
                    { key: "updated_at", class: "text-nowrap"},
                ],
                rows: []
            }
        };
    },
    mounted() {
        this.access = this.hasAnyPermission(['CAN_ASSIGN_SHIFTS']);
        this.onPageLoad();
        this.getShifts();
    },
    methods: {
        alertCountDownChanged(alertDismissCountDown) {
            this.alertDismissCountDown = alertDismissCountDown;
        },
        info(item, index, button) {
            this.infoModal.title = `Deactivate Confirmation`;
            this.message = 'Are you sure you want to Deactivate the Employee Shift Of <b>'+item.employee.full_name+'</b>?';
            this.infoModal.content = item.id;
            this.$root.$emit("bv::show::modal", this.infoModal.id, button);
        },
        resetInfoModal() {
            this.infoModal.title = "";
            this.infoModal.content = "";
        },
        deleteEmployeeShift: function(shift_id) {
            const data = {
                id: shift_id
            };
            this.startProgressBar();
            this.axios
                .post(
                    this.$api.delete_employee_shift,
                    data,
                    this.getAuthHeaders()
                )
                .then(response => {
                    if (response.data.code == 200) {
                        this.$root.$emit("bv::hide::modal", this.infoModal.id);
                        this.deleted = "Employee Shift Deactivated Successfully.";
                        this.getEmployeeShifts(this.getDefaultFilters());
                    }
                    this.updateProgressBar(true);
                })
                .catch(err => {
                    if (!err.response) {
                        this.api_error = default_error.server_error;
                    } else if (
                        err.response &&
                        err.response.data &&
                        err.response.data.message
                    ) {
                        this.api_error = err.response.data.message;
                    } else {
                        this.api_error = default_error.server_error;
                        console.error(err.response.data);
                    }
                    this.updateProgressBar(false);
                    this.showAlert();
                    window.scrollTo(0, 0);
                });
        },
        cancel: function() {
            this.$root.$emit("bv::hide::modal", this.infoModal.id);
        },

        showAlert() {
            this.alertDismissCountDown = this.alertDismissSecs;
        },
        setBadgeColor(value) {
            let data = this.badge_colors.find(status => status.status == value);
            return data.color;
        },
        getEmpId(id) {
            this.filter = id;
        },
        onPageLoad:function(){
            this.startProgressBar();
            var urlBody =
                Object.keys(this.$route.query).length > 0
                    ? this.$route.query
                    : this.getDefaultFilters();
            this.setDefaultFilters();
            this.getEmployeeShifts(urlBody); 
        },

        setDefaultFilters() {
            let api_params = this.deleteEmptyKeys(this.$route.query);
            this.urlBody.shift_id = api_params.shift_id
                ? api_params.shift_id
                : this.urlBody.shift_id;
            this.urlBody.sort_by = api_params.sort_by
                ? api_params.sort_by
                : this.urlBody.sort_by;
            this.urlBody.order = api_params.order
                ? api_params.order
                : this.urlBody.order;
            this.filter = api_params.query
                ? api_params.query
                : this.filter;
            this.urlBody.page = api_params.page
                ? api_params.page
                : this.urlBody.page;
            this.urlBody.per_page = api_params.per_page
                ? api_params.per_page
                : this.urlBody.per_page;
        },

        onSelection() {
            var query =this.deleteEmptyKeys(this.urlBody);
            this.getEmployeeShifts(query);
            this.$refs.employeeId.reset();
        },

        onFiltered() {
           if (this.filter) {
                this.getEmployeeShifts({query:this.filter});
            }
        },

        getDefaultFilters() {
            let api_params = {
                page: this.urlBody.page,
                per_page: this.urlBody.per_page,
                sort_by: this.urlBody.sort_by,
                order: this.urlBody.order,
                shift_id: this.urlBody.shift_id
            };
            return api_params;
        },

        getShifts: function(){
            this.startProgressBar();
            var query = this.getAuthHeaders();
            query["params"] = {};
            this.axios
                .get(this.$api.get_working_shift_list, query)
                .then(response => {
                    response.data.data.forEach(shift => {
                        this.shifts.push({
                            value:shift.id, text: shift.name
                        })
                    });
                    this.updateProgressBar(true);
                })
                .catch(err => {
                    if (!err.response) {
                        this.api_error = default_error.server_error;
                    } else if (
                        err.response &&
                        err.response.data &&
                        err.response.data.message
                    ) {
                        this.api_error = err.response.data.message;
                    } else {
                        this.api_error = default_error.server_error;
                        console.error(err.response.data);
                    }
                    this.items = [];
                    this.updateProgressBar(false);
                    this.showAlert();
                });
        },

        getEmployeeShifts: function(params) {
            this.startProgressBar();
            var query = this.getAuthHeaders();
            query["params"] = this.deleteEmptyKeys(params);
            this.axios
                .get(this.$api.get_employee_shifts, query)
                .then(response => {
                    const result = response.data.data;
                    this.items = result.data;
                    this.urlBody.page = result.current_page;
                    this.urlBody.per_page = result.per_page;
                    this.lastPage = result.last_page;
                    this.totalRows = result.total;
                    this.deleted = "";
                    this.api_error = "";
                    if(params.query) {
                        this.$refs.employeeId.getSelectedEmployee(params.query);
                    }
                    this.updateProgressBar(true);
                    this.$router
                        .push({
                            path: this.$route.params[0],
                            query: this.deleteEmptyKeys(params)
                        }).catch(()=>{})
                })
                .catch(err => {
                    if (!err.response) {
                        this.api_error = default_error.server_error;
                    } else if (
                        err.response &&
                        err.response.data &&
                        err.response.data.message
                    ) {
                        this.api_error = err.response.data.message;
                    } else {
                        this.api_error = default_error.server_error;
                        console.error(err.response.data);
                    }
                    this.items = [];
                    this.updateProgressBar(false);
                    this.showAlert();
                });
        },
        customSorting: function(ctx) {
            if (ctx.sortBy == "" || ctx.sortBy == null) {
                return;
            }
            let urlBody = this.deleteEmptyKeys(this.$route.query);
            urlBody.order = ctx.sortDesc ? "asc" : "desc";
            urlBody.sort_by = ctx.sortBy;
            urlBody.page = 1;
            this.api_error = "";
            this.getEmployeeShifts(urlBody);
        },
        checkPermit: function(permission) {
            return this.hasPermission(permission);
        },
        pagination: function(pageNumber) {
            var newQuery = this.deleteEmptyKeys(this.$route.query);
            newQuery.page = pageNumber;
            this.api_error = "";
            this.getEmployeeShifts(newQuery);
        },
        clearSearch (event) {
            if (event.target.value === ""){
                if(this.urlBody.query != ''){
                    this.urlBody.query = '';
                    var newQuery = this.deleteEmptyKeys(this.urlBody);
                    this.$router
                        .push({
                            path: this.$route.params[0],
                            query: newQuery
                        })
                    this.getEmployeeShifts(newQuery)
                }
            }
        },

        changePageCount: function() {
            let newQuery = this.deleteEmptyKeys(this.$route.query);
            newQuery.per_page = this.urlBody.per_page;
            this.deleted = "";
            this.api_error = "";
            this.$router
                .push({
                    path: this.$route.params[0],
                    query: newQuery
                })
                .catch(err => {
                    console.error(err);
                });
            this.getEmployeeShifts(newQuery);
        },

        dateformat (date) {
            var dateFormat = new Date(date);
            var dd = String(dateFormat.getDate()).padStart(2, '0');
            var mm = String(dateFormat.getMonth() + 1).padStart(2, '0');
            var yyyy = dateFormat.getFullYear();
            return dd + '-' + mm + '-' + yyyy;
        },

        week_offs(value){
            let data= JSON.parse(value);
            if (data){
            return data.join(', ');
            } 
            return data;
        },

        openHistory(item, button) {
            this.history.rows = item.history ? JSON.parse(item.history) : [];
            this.$root.$emit("bv::show::modal", this.history.id, button);
        }

    }
};
</script>
<style lang="scss">
@import "../../../assets/css/custom.scss";
#employee-shift-history-modal .modal-body {
    padding: 0 !important;
}
</style>
